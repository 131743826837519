import { useEffect, useRef, useState } from "react";
import { LinkNotification } from "../types/linkSettings";
import LinkSettingsAPI from "../services/linkSettingsAPI";
import EntityAPI from "../services/entity";
import ConfirmationModal from "../common/ConfirmationModal";
import TagsSelector from "../common/tagsSelector";

const Settings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState<LinkNotification[]>([]);
    const [originalNotifications, setOriginalNotifications] = useState<LinkNotification[]>([]);

    const [hasChanges, setHasChanges] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const savedTimeout = useRef<NodeJS.Timeout | null>(null);

    // Initialize with default values
    const [linkTags, setLinkTags] = useState<string[]>([]);
    const [originalLinkTags, setOriginalLinkTags] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>(['Employee', 'Candidate', 'Other']);
    const [originalTags, setOriginalTags] = useState<string[]>(['Employee', 'Candidate', 'Other']);
    const [newTag, setNewTag] = useState('');
    const [tagToRemove, setTagToRemove] = useState<string | null>(null);
    const [isTagConfirmationModalOpen, setIsTagConfirmationModalOpen] = useState<boolean>(false);
    const [alreadyTagExist, setAlreadyTagExist] = useState(false);


    // Add state for notification removal confirmation
    const [indexToRemove, setIndexToRemove] = useState<number | null>(null);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const [sendToAssessmentTaker, setSendToAssessmentTaker] = useState({
        enabled: true,
        notificationType: 'default',
    });
    const [originalSendToAssessmentTaker, setOriginalSendToAssessmentTaker] = useState({
        enabled: true,
        notificationType: 'default',
    });

    const [userAccessibility, setUserAccessibility] = useState(false);
    const [originalUserAccessibility, setOriginalUserAccessibility] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setIsLoading(true);
                // Fetch link settings
                const linkSettings = await LinkSettingsAPI.getDefaultLinkSettings();

                // Initialize notifications
                const fetchedNotifications = linkSettings.linkInfo.settings.notifications || [];
                setNotifications(fetchedNotifications);
                setOriginalNotifications([...fetchedNotifications]);

                // Initialize assessment taker settings
                const fetchedAssessmentTaker = linkSettings.linkInfo.sendToAssessmentTaker || {
                    enabled: true,
                    notificationType: 'default',
                };
                setSendToAssessmentTaker(fetchedAssessmentTaker);
                setOriginalSendToAssessmentTaker({ ...fetchedAssessmentTaker });

                // Try to fetch entity settings if user has god mode OR is an admin of the entity
                const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
                if (currentUser?.user?.entityId) {
                    try {
                        const entitySettings = await EntityAPI.getEntityDetails(currentUser.user.entityId);

                        // Explicitly check each value
                        const accessibility = entitySettings.userAccessibility;
                        const entityTags = entitySettings.tags;
                        const linkTags = entitySettings.linkTags;

                        setUserAccessibility(accessibility ?? false);
                        setOriginalUserAccessibility(accessibility ?? false);

                        if (Array.isArray(entityTags) && entityTags.length > 0) {
                            setTags(entityTags);
                            setOriginalTags([...entityTags]);
                        } else {
                            console.log('No tags found in entity settings, using defaults'); // Debug log
                        }

                        if (Array.isArray(linkTags) && linkTags.length > 0) {
                            setLinkTags(linkTags);
                            setOriginalLinkTags(linkTags);
                        }

                    } catch (error: any) {
                        console.log('Entity fetch error:', error); // Debug log
                        // Keep using defaults, but log the specific error
                        console.error('Failed to fetch entity settings:', error.response?.data || error.message);
                    }
                }

                setHasChanges(false);
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
            const entityId = currentUser?.user?.entityId;
            const savePromises = [];

            // Compare and save link settings if changed
            const notificationsChanged = JSON.stringify(notifications) !== JSON.stringify(originalNotifications);
            const assessmentTakerChanged = JSON.stringify(sendToAssessmentTaker) !== JSON.stringify(originalSendToAssessmentTaker);

            if (hasChanges && (notificationsChanged || assessmentTakerChanged)) {
                savePromises.push(
                    LinkSettingsAPI.updateEntityDefaultLinkSettings({
                        notifications,
                        sendToAssessmentTaker,
                    })
                );
            }

            // Compare and save entity settings if changed
            const accessibilityChanged = userAccessibility !== originalUserAccessibility;
            const tagsChanged = JSON.stringify(tags) !== JSON.stringify(originalTags);
            const linkTagsChanged = JSON.stringify(linkTags) !== JSON.stringify(originalLinkTags);

            if (hasChanges && entityId && (accessibilityChanged || tagsChanged || linkTagsChanged)) {
                savePromises.push(
                    EntityAPI.updateEntitySettings(entityId, {
                        userAccessibility,
                        tags,
                        linkTags
                    })
                );
            }

            if (savePromises.length > 0) {
                await Promise.all(savePromises);

                // Update original states after successful save
                if (notificationsChanged || assessmentTakerChanged) {
                    setOriginalNotifications([...notifications]);
                    setOriginalSendToAssessmentTaker({ ...sendToAssessmentTaker });
                }

                if (accessibilityChanged || tagsChanged || linkTagsChanged) {
                    setOriginalUserAccessibility(userAccessibility);
                    setOriginalTags([...tags]);
                    setOriginalLinkTags(linkTags);
                }

                setHasChanges(false);
                setIsSaved(true);

                if (savedTimeout.current) {
                    clearTimeout(savedTimeout.current);
                }
                savedTimeout.current = setTimeout(() => {
                    setIsSaved(false);
                }, 2000);
            }
        } catch (error) {
            console.error('Failed to save settings:', error);
            // Here we should show an error to the user since save failed
            alert('Failed to save settings. Please try again.');
        }
    };

    const handleEmailChange = (index: number, email: string) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications[index].recipientEmail = email;
            return updatedNotifications;
        });
    };

    const handleChange = () => {
        setHasChanges(true);
        setIsSaved(false);
    };

    const handleNotificationTypeChange = (index: number, notificationType: string) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications[index].notificationType = notificationType;
            return updatedNotifications;
        });
    };

    const removeRecipient = (index: number) => {
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications.splice(index, 1);
            return updatedNotifications;
        });
    };

    const addRecipient = () => {
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            { recipientEmail: '', notificationType: '' },
        ]);
    };

    // Add new tag handler
    const handleAddTag = () => {
        const tag = newTag.trim().toLowerCase();
        const lowerCaseTags = tags?.map((t: string) => t.toLowerCase());
        if (tag && !lowerCaseTags.includes(tag)) {
            setTags([...tags, newTag.trim()]);
            setNewTag('');
            handleChange();
        } else {
            setAlreadyTagExist(true);
            setTimeout(() => {
                setAlreadyTagExist(false);
            }, 1000 * 2);
        }
    };

    // Remove tag handler
    const handleRemoveTag = (tagToRemove: string) => {
        if (!['Employee', 'Candidate', 'Other'].includes(tagToRemove)) {
            setTagToRemove(tagToRemove);
            setIsTagConfirmationModalOpen(true);
        }
    };

    // Add new handler for confirmed tag removal
    const confirmTagRemoval = () => {
        if (tagToRemove) {
            setTags(tags.filter(tag => tag !== tagToRemove));
            handleChange();
        }
        setIsTagConfirmationModalOpen(false);
        setTagToRemove(null);
    };

    useEffect(() => {
        // Remove tag from link if they are not exist
        if (tags) {
            setLinkTags((preState) => {
                const mappedTags = preState.filter(t => tags.includes(t));
                return mappedTags;
            });
        }
    }, [tags])

    return (
        <div className="settings-container">
            <h1>Org Settings</h1>
            {isLoading ? (
                <div className="loading-spinner">Loading settings...</div>
            ) : (
                <form onSubmit={handleSave}>
                    <h2>Default Link Notifications</h2>
                    <h3 className="heading-top-margin">Notifications</h3>
                    {notifications.map((notification, index) => (
                        <div key={index} className="notification-row">
                            <input
                                type="email"
                                value={notification.recipientEmail}
                                onChange={(e) => {
                                    handleEmailChange(index, e.target.value);
                                    handleChange();
                                }}
                                placeholder="Recipient Email"
                                required
                                className="notification-input"
                            />
                            <select
                                value={notification.notificationType}
                                onChange={(e) => {
                                    handleNotificationTypeChange(index, e.target.value);
                                    handleChange();
                                }}
                                className="notification-select"
                            >
                                <option value="">Notification Type</option>
                                <option value="default">Completion Notification</option>
                                <option value="fullReport">Full Report</option>
                                <option value="chartOnly">Chart Only</option>
                            </select>
                            {notifications.length > 1 && (
                                <button
                                    type="button"
                                    className="button button-danger"
                                    onClick={() => {
                                        setIndexToRemove(index)
                                        setIsConfirmationModalOpen(true);
                                    }}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="add-recipient-row">
                        <button
                            type="button"
                            className="button button-primary"
                            onClick={() => {
                                addRecipient();
                                handleChange();
                            }}
                        >
                            Add Recipient
                        </button>
                    </div>
                    <div className="assessment-taker-notification heading-top-margin">
                        <h3>Assessment Taker Notification</h3>
                        <div className="notification-row">
                            <label className="notification-input">
                                <input
                                    type="checkbox"
                                    checked={sendToAssessmentTaker.enabled}
                                    onChange={(e) => {
                                        setSendToAssessmentTaker((prev) => ({
                                            ...prev,
                                            enabled: e.target.checked,
                                        }));
                                        handleChange();
                                    }}
                                />
                                <span>Send notification to assessment taker</span>
                            </label>
                            {sendToAssessmentTaker.enabled && (
                                <select
                                    value={sendToAssessmentTaker.notificationType}
                                    onChange={(e) => {
                                        setSendToAssessmentTaker((prev) => ({
                                            ...prev,
                                            notificationType: e.target.value,
                                        }));
                                        handleChange();
                                    }}
                                    className="notification-select"
                                >
                                    <option value="default">Completion Notification</option>
                                    <option value="fullReport">Full Report</option>
                                    <option value="chartOnly">Chart Only</option>
                                </select>
                            )}
                        </div>
                    </div>
                    <div className="user-accessibility-section heading-top-margin">
                        <h2>User Accessibility</h2>
                        <div className="notification-row">
                            <label className="notification-input">
                                <input
                                    type="checkbox"
                                    checked={userAccessibility}
                                    onChange={(e) => {
                                        setUserAccessibility(e.target.checked);
                                        handleChange();
                                    }}
                                />
                                Enable user self-service profile access
                            </label>
                        </div>
                        <p className="help-text">
                            When enabled, users who have completed the assessment can access their own profile
                            readouts and attribute charts. They can log in using their assessment email address
                            and the six digits of their unique user code (found in the PDF filename
                            of any exported profile or charts file,
                            e.g., "Thor_Odinson_<strong>2e23f3</strong>.pdf" would use "<strong>2e23f3</strong>" as the password).
                        </p>
                    </div>

                    {/* Tag Settings for Default Link */}
                    <div className="tags-section heading-top-margin">
                        <h2>Tag Settings for Default Link</h2>

                        <p className="help-text">
                            Enhance your link configurations by assigning optional tags that are
                            automatically added to users when they take an assessment through your
                            organization's default assessment link.
                        </p>
                        <TagsSelector
                            availableTags={tags}
                            selectedTags={linkTags}
                            addTagHandler={(tag) => {
                                setLinkTags(preState => [...preState, tag])
                                handleChange();
                            }}
                            removeTagHandler={(tag) => {
                                setLinkTags(preState => preState.filter(t => t !== tag));
                                handleChange();
                            }}
                        />
                    </div>

                    {/* Tags */}
                    <div className="tags-section heading-top-margin">
                        <h2>Organization Tags</h2>
                        <p className="help-text">
                            Configure the available tags for categorizing people in your organization.
                            Default tags cannot be removed, but you can add custom tags specific to your needs.
                        </p>
                        <div className="tags-list">
                            {tags.map((tag) => (
                                <div key={tag} className="tag-row">
                                    <span>{tag}</span>
                                    {!['Employee', 'Candidate', 'Other'].includes(tag) && (
                                        <button
                                            type="button"
                                            className="button button-danger button-sm"
                                            onClick={() => handleRemoveTag(tag)}
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="add-tag">
                            <input
                                type="text"
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                placeholder="New tag"
                                className="notification-input"
                            />
                            <button
                                type="button"
                                className="button button-primary"
                                onClick={handleAddTag}
                                disabled={!newTag.trim()}
                            >
                                Add Tag
                            </button>
                        </div>
                        {
                            alreadyTagExist
                                ? <p className="error-message">There is already a tag with that name</p>
                                : null
                        }
                    </div>

                    {/* Action buttons */}
                    <div className="notification-actions">
                        <button
                            type="button"
                            className="button button-cancel"
                            onClick={() => {
                                setHasChanges(false);
                                setIsSaved(false);
                            }}
                            disabled={!hasChanges}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`button ${hasChanges ? 'button-save' : 'button-disabled'}`}
                            disabled={!hasChanges}
                        >
                            {isSaved ? 'Saved' : hasChanges ? 'Save Changes' : 'No Changes'}
                        </button>
                    </div>
                </form>
            )}
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setIsConfirmationModalOpen(false)}
                confirm={() => {
                    setIsConfirmationModalOpen(false);
                    if (indexToRemove !== null) {
                        removeRecipient(indexToRemove);
                        handleChange();
                    }
                }}
                question="Are you sure you want to remove this recipient?"
            />
            <ConfirmationModal
                isOpen={isTagConfirmationModalOpen}
                onClose={() => setIsTagConfirmationModalOpen(false)}
                confirm={confirmTagRemoval}
                question={`Deleting this tag here in Org Settings will also remove it from any existing users who have it assigned to them. Are you sure you want to remove "${tagToRemove}" from the tags?`}
            />
        </div>
    );
}

export default Settings;